import {Configure, InstantSearch} from 'react-instantsearch-hooks-web'
import Filter from './Filter'
import Header from './Header'
import {getSearchClient, indexName} from './helpers/algoliasearch'
import {useCookies} from 'react-cookie'
import {useEffect} from 'react'
const searchClient = getSearchClient()

function App() {
	const [, setCookie] = useCookies(['ref_code'])
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		const ref_code = queryParams.get('ref')
		if (ref_code !== null)
			setCookie('ref_code', ref_code, {path: '/', maxAge: 86400})
		const {origin, pathname} = window.location
		if (queryParams.has('ref')) window.location.replace(origin + pathname)
	}, [setCookie])
	return (
		<div>
			<Header />
			<InstantSearch searchClient={searchClient} indexName={indexName}>
				<Configure />
				<Filter />
			</InstantSearch>
		</div>
	)
}

export default App

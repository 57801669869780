import {
	Box,
	Checkbox,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Alert,
	Button,
	Typography,
	Link,
} from '@mui/material'
import {
	useRefinementList,
	useMenu,
	useClearRefinements,
} from 'react-instantsearch-hooks-web'
import {useCookies} from 'react-cookie'
import Opportunities from './Opportunities'
import useCurrentSelection from './helpers/useCurrentSelection'
import getFormUrl, {formBaseUrl} from './helpers/formUrlGenerator'
import {useEffect, useContext} from 'react'
import {AppContext} from './Context'

function Filter() {
	const [cookie] = useCookies(['ref_code'])
	const {shareYourInterests, searchOpportunities} = useContext(AppContext)
	return (
		<Container sx={{mt: '10px', mb: '60px'}} maxWidth="lg">
			<Box sx={{display: 'flex', flexDirection: 'column'}}>
				{searchOpportunities && (
					<>
						<FilterBar />
						<Opportunities />
					</>
				)}
			</Box>
			{!shareYourInterests && (
				<Typography textAlign="center" sx={{mt: '50px'}}>
					Seems you haven't explored the available opportunities yet.
					If you still want to proceed,{' '}
					<Link
						component="a"
						href={`${formBaseUrl}${
							cookie.ref_code === undefined || null
								? ''
								: `?tfa_28800=${cookie.ref_code}`
						}`}
					>
						Click here to record your interest.
					</Link>
				</Typography>
			)}
		</Container>
	)
}
export default Filter

function FilterBar() {
	useEffect(() => {
		window.scrollBy(0, window.innerHeight)
	}, [])
	let {mode, city, cluster, availability} = useCurrentSelection()
	const {selectedSubject, selectedData} = useContext(AppContext)
	let displayMessage = null
	let filterMenuConfig = [{type: 'menu', attribute: 'mode'}]

	if (mode.hasSelected && mode.value === 'Offline') {
		filterMenuConfig.push({type: 'menu', attribute: 'city'})
	}

	if (city.hasSelected) {
		filterMenuConfig.push({type: 'list', attribute: 'cluster'})
	}
	if (
		mode.hasSelected &&
		(mode.value === 'Online' ||
			(mode.value === 'Offline' &&
				cluster.hasSelected &&
				selectedData.current.cluster.value?.length <= 3))
	) {
		filterMenuConfig.push({type: 'list', attribute: 'availability'})
	}
	let filterMenus = filterMenuConfig.map(menu => {
		let component
		if (menu.type === 'menu') {
			component = <FilterMenu attribute={menu.attribute} />
		} else {
			component = <FilterList attribute={menu.attribute} />
		}
		return (
			<Grid item xs={1} key={menu.attribute}>
				{component}
			</Grid>
		)
	})

	switch (filterMenus.length) {
		case 1:
			displayMessage =
				"Let's get started by selecting your volunteering preferences for mode."
			selectedSubject.current = []
			break
		case 2:
			displayMessage = 'Now select your city volunteering preferences.'
			if (mode.value === 'Online')
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			if (mode.value === 'Online' && availability.hasSelected)
				displayMessage =
					'Here is a list of Opportunities based on selected preferences. Select Opportunities  (Max. 2 Subjects) and Apply.'
			selectedSubject.current = []
			break
		case 3:
			displayMessage =
				'Now select your city cluster volunteering preferences.'
			selectedSubject.current = []
			break
		case 4:
			displayMessage =
				'Here is a list of Opportunities based on selected preferences. Select Opportunities  (Max. 2 Subjects) and Apply.'
			if (availability.hasSelected === false)
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			selectedSubject.current = []
			break
		default:
			displayMessage = null
	}

	if (cluster.hasSelected && selectedData.current.cluster.value?.length > 3) {
		displayMessage =
			'Sorry, you can shortlist a maximum of 3 clusters only.'
	}

	return (
		<>
			{displayMessage && (
				<Alert
					sx={{
						mb: '20px',
					}}
					severity="info"
				>
					{displayMessage}
				</Alert>
			)}
			<Box
				container
				sx={{
					display: 'flex',
					flexDirection: {xs: 'column', sm: 'column', md: 'row'},
					maxWidth: {md: '94%'},
				}}
			>
				<Grid container columns={{xs: 1, sm: 2, md: 4}} spacing={1}>
					{filterMenus}
				</Grid>
				{availability.hasSelected && <CustomClearRefinements />}
			</Box>
		</>
	)
}

function FilterList({attribute}) {
	const {items, refine} = useRefinementList({attribute})
	return (
		<SelectField
			attribute={attribute}
			value={items.filter(v => v.isRefined).map(v => v.value)}
			multiple={true}
			items={items}
			refine={refine}
		/>
	)
}

function FilterMenu({attribute}) {
	const {items, refine} = useMenu({attribute})
	return (
		<SelectField
			attribute={attribute}
			value={items.find(v => v.isRefined)?.label ?? ''}
			multiple={false}
			items={items}
			refine={refine}
		/>
	)
}

function CustomClearRefinements(props) {
	const {refine} = useClearRefinements(props)
	const {selectedSubject} = useContext(AppContext)
	return (
		<Button
			sx={{
				my: '10px',
				marginLeft: '10px',
			}}
			variant="outlined"
			onClick={() => {
				selectedSubject.current = []
				refine()
			}}
		>
			Reset
		</Button>
	)
}

function SelectField({multiple, attribute, value, items, refine}) {
	return (
		<FormControl fullWidth>
			<InputLabel id={`${attribute}-menu`}>{attribute}</InputLabel>
			<Select
				labelId={`${attribute}-menu`}
				id={`${attribute}-select`}
				value={value}
				renderValue={value =>
					Array.isArray(value) ? value.join(', ') : value
				}
				label={attribute}
				multiple={multiple}
			>
				{items.map(item => (
					<MenuItem
						key={item.label}
						value={item.label}
						onClick={() => refine(item.value)}
					>
						{multiple && <Checkbox checked={item.isRefined} />}
						{item.label} ({item.count})
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

import {useCurrentRefinements} from 'react-instantsearch-hooks-web'
import {AppContext} from '../Context'
import {useContext} from 'react'

function checkHasSelected(items, attribute) {
	if (!Array.isArray(items)) return false
	return items.some(item => item.attribute === attribute)
}

function useCurrentSelection() {
	const {selectedData} = useContext(AppContext)
	const attributes = ['mode', 'city', 'cluster', 'availability']
	const {items} = useCurrentRefinements({
		includedAttributes: attributes,
	})

	let result = Object.fromEntries(
		attributes.map(a => [a, {hasSelected: false, value: null}])
	)

	selectedData.current = Object.fromEntries(
		attributes.map(a => [a, {hasSelected: false, value: null}])
	)

	attributes.forEach(attribute => {
		let hasSelected = checkHasSelected(items, attribute)
		result[attribute].hasSelected = hasSelected
		if (hasSelected) {
			result[attribute].value = items.find(
				item => item.attribute === attribute
			).refinements[0].value

			selectedData.current[attribute].value = items
				.find(item => item.attribute === attribute)
				.refinements.map((items, index) => items.value)
		}
	})

	return result
}
export default useCurrentSelection

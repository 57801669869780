import {useHits, usePagination} from 'react-instantsearch-hooks-web'
import {useState, useContext} from 'react'
import {
	Grid,
	Card,
	CardActionArea,
	CardHeader,
	CardContent,
	Typography,
	Container,
	Box,
	Pagination,
	Button,
	Alert,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import getFormUrl, {formBaseUrl} from './helpers/formUrlGenerator'
import {useCookies} from 'react-cookie'
import useCurrentSelection from './helpers/useCurrentSelection'
import {AppContext} from './Context'

function Opportunities() {
	const {selectedSubject, selectedData} = useContext(AppContext)
	let obj = {
		mode: null,
		city: null,
		cluster: [],
		availability: [],
		subject1: [],
		subject2: [],
		ref_code: null,
	}
	const [errorMessage, setErrorMessage] = useState(null)
	const [cardPagination, setCardPagination] = useState({
		startPage: 0,
		endPage: 8,
		pageValue: 1,
	})
	const [, setChecked] = useState(false)
	const sections = useCurrentSelection()
	const {hits} = useHits()
	const [cookie] = useCookies(['ref_code'])
	const errorMsgDuration = 5000
	let cardPaginationPages
	function validSubjectCheck() {
		if (selectedSubject.current.length === 0) {
			setErrorMessage(
				'Please select at least 1 subject preference. You can modify it later too if needed.'
			)
			return false
		}
		if (selectedSubject.current.length > 2) {
			setErrorMessage(
				'Sorry, You can only select a maximum of 2 subjects preferences.'
			)
			return false
		}
		return true
	}

	function urlBuilder() {
		obj = {
			mode: sections.mode.value,
			city: sections.mode.value === 'Online' ? null : sections.city.value,
			cluster:
				sections.mode.value === 'Online'
					? null
					: selectedData.current.cluster.value.join(';'),
			availability: selectedData.current.availability.value,
			subject1: selectedSubject.current[0],
			subject2: selectedSubject.current[1],
			ref_code: cookie.ref_code,
		}
	}
	// grouped city-cluster And availability for all same subjects hits (object) for (Subject-card)
	// if subject have diffrent city-cluster And availability  Return one grouped  (customHits)
	function customHits(hits) {
		let subjects = hits.map(item => item.subject)
		let uniqueSubjectList = subjects.filter(onlyUnique)
		let subjectList
		let filterHits = []
		let filterHitsObj = {}
		uniqueSubjectList.forEach(item => {
			subjectList = {...subjectList, [item]: 0}
		})
		hits.forEach(item => {
			if (subjectList[item.subject] === 0) {
				subjectList[item.subject] = subjectList[item.subject] + 1
				// filterHits.push(item)
				filterHitsObj[item.subject] = item
			}
		})
		hits.forEach((item, index) => {
			if (filterHitsObj[item.subject].subject === item.subject) {
				if (!filterHitsObj[item.subject].cluster.includes(item.cluster))
					filterHitsObj[item.subject] = {
						...filterHitsObj[item.subject],
						cluster:
							filterHitsObj[item.subject].cluster +
							', ' +
							item.cluster,
					}
				item.availability.forEach(_item => {
					if (
						!filterHitsObj[item.subject].availability.includes(
							_item
						)
					)
						filterHitsObj[item.subject] = {
							...filterHitsObj[item.subject],
							availability: [
								...filterHitsObj[item.subject].availability,
								_item,
							],
						}
				})
			}
		})

		filterHits = Object.keys(filterHitsObj).map(function (key) {
			return filterHitsObj[key]
		})

		//utill fun
		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index
		}
		// Subjects card custom cardPagination
		cardPaginationPages = filterHits.length
		if (cardPaginationPages > 8) {
			return filterHits.slice(
				cardPagination.startPage,
				cardPagination.endPage
			)
		} else {
			if (cardPagination.pageValue > 1)
				setCardPagination({startPage: 0, endPage: 8, pageValue: 1})
			return filterHits.slice(0, 8)
		}
		// return filterHits (if card pagination don't need)
	}
	return (
		sections.availability.hasSelected && (
			<Container disableGutters sx={{mb: 4}}>
				<Box textAlign="center" sx={{my: 4}}>
					{errorMessage && (
						<Alert sx={{p: '0px', m: '0px'}} severity="warning">
							{errorMessage}
						</Alert>
					)}
				</Box>
				<Grid
					container
					spacing={2}
					columns={{xs: 1, md: 3, xl: 4}}
					alignItems="stretch"
				>
					{customHits(hits).map(hit => {
						return (
							<Grid item xs={1} key={hit.objectID}>
								<Card
									key={hit.objectID}
									variant="outlined"
									sx={{height: 1}}
									elevation={0}
									style={{
										backgroundColor:
											selectedSubject.current[0] ===
												hit.subject ||
											selectedSubject.current[1] ===
												hit.subject
												? '#e0e0e0'
												: 'white',
										// backgroundColor: "grey"
									}}
									onClick={() => {
										if (
											selectedSubject.current[0] !==
												hit.subject &&
											selectedSubject.current[1] !==
												hit.subject &&
											selectedSubject.current.length < 2
										) {
											setErrorMessage(null)
											selectedSubject.current.push(
												hit.subject
											)
											if (
												selectedSubject.current
													.length === 1 &&
												hits.length > 1
											)
												setErrorMessage(
													'You may also choose a 2nd subject preference if you wish to'
												)
										} else {
											selectedSubject.current =
												selectedSubject.current.filter(
													function (value) {
														return (
															value !==
															hit.subject
														)
													}
												)
										}
										if (
											selectedSubject.current.length ===
												2 &&
											selectedSubject.current[0] !==
												hit.subject &&
											selectedSubject.current[1] !==
												hit.subject
										) {
											setErrorMessage(
												'Sorry, You can select only 2 subject preferences'
											)
											setTimeout(
												() => setErrorMessage(null),
												errorMsgDuration
											)
										}
										setChecked(c => !c)
									}}
								>
									<CardActionArea>
										<CardHeader
											title={hit.subject}
											subheader={
												sections.mode.value ===
												'Offline'
													? `${hit.city}, ${hit.cluster}`
													: ''
											}
											titleTypographyProps={{
												variant: 'subtitle1',
												textTransform: 'uppercase',
											}}
											avatar={
												<CheckCircleIcon
													sx={{
														color:
															selectedSubject
																.current[0] ===
																hit.subject ||
															selectedSubject
																.current[1] ===
																hit.subject
																? '#0EC0E2'
																: '#dfe6eb',
													}}
												/>
											}
										/>
										<CardContent sx={{pt: 0}}>
											<Typography
												variant="body2"
												color="text.secondary"
											>
												{hit.availability
													?.map(timeSlot => {
														const availabilityMap =
															{
																'Weekday (Anytime between 7:00 AM - 12.00 PM)':
																	'Weekday - morning',
																'Weekday (Anytime between 12:00 PM - 3:00 PM)':
																	'Weekday - afternoon',
																'Weekday (Anytime between 3:00 PM - 6:00 PM)':
																	'Weekday - early evening',
																'Weekday (Anytime between 6:00 PM - 9:00 PM)':
																	'Weekday - late evening',
																'Weekend (Saturdays - for a few hours)':
																	'Weekend - Saturday',
															}
														return availabilityMap[
															timeSlot?.trim()
														]
													})
													?.join(', ')}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						)
					})}
				</Grid>
				<Box sx={{my: 4}}>
					<Pagination
						count={Math.ceil(cardPaginationPages / 8)}
						page={
							cardPaginationPages > 8
								? cardPagination.pageValue
								: 1
						}
						onChange={(e, value) => {
							if (value === cardPagination.pageValue) return
							if (value > cardPagination.pageValue) {
								setCardPagination({
									startPage: cardPagination.startPage + 8,
									endPage: cardPagination.endPage + 8,
									pageValue: value,
								})
							} else {
								setCardPagination({
									startPage: cardPagination.startPage - 8,
									endPage: cardPagination.endPage - 8,
									pageValue: value,
								})
							}
						}}
					/>
				</Box>
				<Box textAlign="center" sx={{my: 4}}>
					{window.screen.width >= 800
						? null
						: errorMessage && (
								<Alert
									sx={{p: '0px', m: '0px'}}
									severity="warning"
								>
									{errorMessage}
								</Alert>
						  )}
					<Typography>
						Please note that expressing interest in a specific
						opportunity does not assure allotment to the same.
					</Typography>
					<Button
						sx={{width: 200, p: 1, m: 2}}
						variant="outlined"
						onClick={() => {
							if (validSubjectCheck()) {
								urlBuilder()
								window.open(getFormUrl(obj), '_self')
							}
						}}
					>
						Apply Now{' '}
					</Button>
					{/* <Typography>
						Can't find the exact opportunity you're looking for? You
						can still{' '}
						<Link
							component="a"
							href={`${formBaseUrl}${
								cookie.ref_code === undefined || null
									? ''
									: `?tfa_28800=${cookie.ref_code}`
							}`}
						>
							Click here to record your interest.
						</Link>
					</Typography> */}
				</Box>
			</Container>
		)
	)
}

export default Opportunities

//Pagination base on algolia react-instantsearch-hooks-web,
// can not use with grouped city-cluster And availability for all same subjects hits (object) for (Subject-card), to use (define hitsPerPage={8} in app.js)
function HitPages() {
	const pages = usePagination()
	const {nbPages, refine, currentRefinement, canRefine} = pages

	if (canRefine)
		return (
			<Pagination
				count={nbPages}
				page={currentRefinement + 1}
				onChange={(e, value) => refine(value - 1)}
			/>
		)
	else return null
}

import MuiAppBar from '@mui/material/AppBar'
import {Box, Button, Stack, Container} from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import {Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import {formBaseUrl} from './helpers/formUrlGenerator'
import {useCookies} from 'react-cookie'
import {AppContext} from './Context'
import {useContext} from 'react'

const AppBar = styled(MuiAppBar)({
	backgroundColor: '#1a1a1a',
})

function Header() {
	const {shareYourInterests, searchOpportunities} = useContext(AppContext)
	const showDowntime = process.env.REACT_APP_DOWNTIME
	return (
		<>
			<AppBar position="static" color="inherit">
				<Toolbar>
					<ButtonBase
						component="a"
						href="https://teachforindia.org/volunteer"
					>
						<Box
							sx={{
								height: '56px',
								padding: 0,
							}}
							component="img"
							src="/logo.png"
						></Box>
					</ButtonBase>

					<Typography sx={{color: 'white'}}>
						Volunteering Opportunities
					</Typography>
				</Toolbar>
			</AppBar>
			{!showDowntime && (
				<Container
					sx={{
						display: 'flex',
						mt: {
							xs: 'column',
							sm: 'column',
							md:
								searchOpportunities || !shareYourInterests
									? '0px'
									: '25vh',
						},
						flexDirection: {xs: 'column', sm: 'column', md: 'row'},
					}}
					maxWidth="lg"
				>
					<HomePageButtons />
				</Container>
			)}
			{showDowntime && (
				<Container
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingY: 4,
					}}
					maxWidth="lg"
				>
					<Box
						sx={{
							backgroundColor: '#f5f5f5',
							padding: '30px',
							borderRadius: '12px',
							boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
							maxWidth: {xs: '100%', md: '75%'},
							textAlign: 'center',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 'bold',
								color: '#089eb6',
								marginBottom: '12px',
							}}
						>
							Scheduled Maintenance in Progress: Services Will
							Resume by {showDowntime}
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: '#333',
								fontSize: '1rem',
								lineHeight: 1.6,
							}}
						>
							Our services are currently undergoing scheduled
							maintenance. We expect to be back online by{' '}
							{showDowntime}. Thank you for your patience and
							understanding!
						</Typography>
					</Box>
				</Container>
			)}
		</>
	)
}

const HomePageButtons = () => {
	const {
		shareYourInterests,
		setShareYourInterests,
		searchOpportunities,
		setSearchOpportunities,
	} = useContext(AppContext)
	const [cookie] = useCookies(['ref_code'])
	return (
		<>
			<Button
				sx={{
					width: {md: '50%'},
					m: '20px',
					p: '20px',
					backgroundColor:
						searchOpportunities === true ? '#0ec4e2' : 'White',
					color: searchOpportunities === true ? 'white' : '#089eb6',
					'&:hover': {
						backgroundColor: '#0ec4e2',
						color: 'white',
					},
				}}
				style={{borderRadius: 20}}
				variant="outlined"
				onClick={() => {
					setShareYourInterests(true)
					setSearchOpportunities(true)
				}}
			>
				<Stack spacing={1.5}>
					<Typography sx={{fontWeight: 'bold'}} variant="h6">
						Search for available opportunities
					</Typography>
					<Typography variant="body2" sx={{textTransform: 'none'}}>
						Browse through open volunteering options and directly
						apply to opportunities that interest you.
					</Typography>
				</Stack>
			</Button>

			<Button
				sx={{
					width: {md: '50%'},
					m: '20px',
					p: '20px',
					backgroundColor: shareYourInterests ? 'White' : '#0ec4e2',
					color: shareYourInterests ? '#089eb6' : 'white',
					'&:hover': {
						backgroundColor: '#0ec4e2',
						color: 'white',
					},
				}}
				style={{borderRadius: 20}}
				variant="outlined"
				onClick={() => {
					if (shareYourInterests && searchOpportunities) {
						window.open(
							`${formBaseUrl}${
								cookie.ref_code === undefined || null
									? ''
									: `?tfa_28800=${cookie.ref_code}`
							}`,
							'_self'
						)
						return
					}
					setShareYourInterests(false)
					setSearchOpportunities(false)
				}}
			>
				<Stack spacing={1.5}>
					<Typography sx={{fontWeight: 'bold'}} variant="h6">
						Share Your Volunteering Interests
					</Typography>
					<Typography variant="body2" sx={{textTransform: 'none'}}>
						Can't find what you're looking for? Share your own
						interest entry, It may take us a while to find a
						classroom that matches your preferences.
					</Typography>
				</Stack>
			</Button>
		</>
	)
}
export default Header
